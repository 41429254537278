import { BrandButton } from '@/components/common/BrandButton.tsx'
import { CsrfTokenWithDjango } from '@/components/common/CsrfTokenWithDjango.tsx'
import { LoginTextFiled } from '@/components/form/LoginTextFiled.tsx'
import { REG } from '@/library/reg'
import { LoginFormFieldValues as FormFieldValues } from '@/types/form'
import {
  Button,
  CheckBox,
  TurtleTheme,
  useTurtleTheme,
} from '@dmm-com/turtle-components'
import { css } from '@emotion/react'
import { FormEvent, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Layout } from '@/components/common/Layout.tsx'
import { createRoot } from '@/createRoot.tsx'
import { useDjangoCtx } from '@/hooks/useDjangoCtx'

interface Context {
  data?: Partial<FormFieldValues>
  errors?: {
    invalid_login?: string
    inactive?: string
  }
}

const ON_FLAG = 'on'

const LoginPage = () => {
  const { data, errors: errorsFromServer } = useDjangoCtx<Context>()

  const theme = useTurtleTheme()
  const styles = createStyles(theme)

  const {
    register,
    trigger,
    formState: { errors },
  } = useForm<FormFieldValues>({
    mode: 'onBlur', // focusが外れた際にバリデーションを実行
    defaultValues: data,
  })

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      const result = await trigger()
      if (!result) {
        event.preventDefault()
        return
      }
    },
    [trigger],
  )

  const errorText =
    (errorsFromServer?.inactive || errorsFromServer?.invalid_login) &&
    '正しいメールアドレスとパスワードを入力してください。(どちらも大文字と小文字は区別されます。)'

  return (
    <Layout>
      <h1 css={styles.h1}>法人会員ログイン</h1>
      <section css={styles.section}>
        <form method="post" onSubmit={handleSubmit}>
          <CsrfTokenWithDjango />

          <LoginTextFiled
            name="username"
            useFormProps={{
              register,
              errors,
            }}
            pattern={{
              value: REG.email,
              message: 'メールアドレスを入力してください',
            }}
            label="メールアドレス"
            placeholder="3dp_print@dmm.com"
            type="email"
            css={[styles.input, styles.firstInput]}
          />

          <LoginTextFiled
            name="password"
            useFormProps={{
              register,
              errors,
            }}
            label="パスワード"
            placeholder="パスワードを入力してください"
            type="password"
            errorText={errorText}
            css={styles.input}
          />

          <p css={styles.body1}>
            ご登録いただきます個人情報は、別途定める「
            <a
              target="_blank"
              rel="noopener noreferrer"
              css={styles.link}
              href="https://terms.dmm.com/privacy/"
            >
              個人情報保護に関して
            </a>
            」に従って取り扱います。記載事項以外の目的に使用されることはございません。「
            <a
              css={styles.link}
              href="/tos.pdf?type=service"
              download={'DMM.make 3DPRINT 法人受発注サービス利用規約.pdf'}
            >
              DMM.make 3DPRINT 法人受発注サービス利用規約
            </a>
            」 「
            <a
              css={styles.link}
              href="/tos.pdf?type=print"
              download={
                '【法人用】DMM.make3Dプリントサービス利用約款_20241216改定版.pdf'
              }
            >
              DMM.make3Dプリントサービス利用約款
            </a>
            」をお読みになり、すべての内容に同意の上、ログインしてください。
          </p>

          <BrandButton
            css={styles.submitBtn}
            label="ログイン"
            type="button"
            size="large"
            htmlType="submit"
          />

          <CheckBox
            id="leave_login_info"
            css={styles.checkbox}
            defaultChecked={data?.keep_logged_in === ON_FLAG}
            checkBoxProps={{
              name: 'keep_logged_in',
            }}
            label="ログインしたままにする"
          />

          <Button
            type="link"
            variant="text"
            label="パスワードを忘れた方はこちら"
            Link={({ children }) => {
              return <a href={'/auth/password/reset_request'}>{children}</a>
            }}
          />
        </form>
      </section>
    </Layout>
  )
}

const createStyles = (theme: TurtleTheme) => {
  const { spacing } = theme.turtle
  return {
    wrapper: css`
      width: 100%;
      height: 100%;
    `,
    h1: css`
      ${theme.turtle.typography.heading1};
    `,
    body1: css`
      ${theme.turtle.typography.body1};
      margin: ${spacing.medium[4]} 0;
    `,
    link: css`
      color: ${theme.turtle.palette.interactiveColors.link};
    `,
    section: css`
      background-color: ${theme.turtle.palette.surfaceColor.primarySurface};
      border-radius: ${theme.turtle.borderRadius.small};
      padding: ${spacing.medium[3]};
      margin-top: ${spacing.medium[1]};
    `,
    input: css`
      max-width: 500px;
      min-width: 300px;
    `,
    firstInput: css`
      margin-bottom: ${spacing.small[5]};
    `,
    submitBtn: css`
      width: 300px;
      min-width: 300px;
      margin-top: ${spacing.medium[1]};
      margin-bottom: ${spacing.small[5]};
    `,
    checkbox: css`
      margin-bottom: ${spacing.small[5]};
    `,
  }
}

createRoot(<LoginPage />)
